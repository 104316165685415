<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="5.5" r="3.5" stroke="#29277D" stroke-width="2" />
    <path
      d="M3 16.65C3 12.9773 5.97731 10 9.65 10H10.35C14.0227 10 17 12.9773 17 16.65V16.65C17 16.8433 16.8433 17 16.65 17H3.35C3.1567 17 3 16.8433 3 16.65V16.65Z"
      stroke="#29277D"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <circle
      cx="15"
      cy="9.5"
      r="3.5"
      fill="#ECF1FE"
      stroke="#29277D"
      stroke-width="2"
    />
    <path
      d="M8 20.65C8 16.9773 10.9773 14 14.65 14H15.35C19.0227 14 22 16.9773 22 20.65C22 20.8433 21.8433 21 21.65 21H8.35C8.1567 21 8 20.8433 8 20.65Z"
      fill="#ECF1FE"
      stroke="#29277D"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>
