import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';
import { isAxiosError } from 'axios';
import { HttpCodes } from '@/core/services/http-client.service';
import { checkAuthFetch } from '@/modules/login/service/login-page.transport';
import { getProfile } from '@/modules/personalArea/profile/service/profile-page.transport.ts';
import {
  DATA_ACCESS_RESTRICTION_FLAG,
  useProfileService,
} from '@/core/services/profile.service.ts';
import { TOAST_VARIANT, useToastNotify } from '@/hooks/use-toast-notify.ts';

export type TMenuLink = {
  url: string;
  title: string;
};

const initAuthChecking = async (next: NavigationGuardNext) => {
  const isAuth = await checkAuthFetch();
  const { openToast } = useToastNotify();

  if (!isAuth) next({ name: 'LoginPage' });

  const pingCheckAuth = setInterval(async () => {
    try {
      await checkAuthFetch();
    } catch (error: unknown) {
      if (!isAxiosError(error)) return;

      if (error.response?.status === HttpCodes.UNAUTHORIZED) {
        clearInterval(pingCheckAuth);
        openToast('Сессия истекла, войдите снова', TOAST_VARIANT.info);

        return next({ name: 'LoginPage' });
      }
    }
  }, 50000);
};

export const tryToEnterPrivateRoute = async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const profile = await getProfile();

    initAuthChecking(next);

    const { initProfileService, checkProfileAccessToData } =
      useProfileService();
    initProfileService(profile);

    const hasRouteRestriction = Array.isArray(to.meta.restrictions);

    if (hasRouteRestriction) {
      const isAllowNext = checkProfileAccessToData(
        to.meta.restrictions as Array<DATA_ACCESS_RESTRICTION_FLAG>,
        profile,
      );

      return isAllowNext ? next() : next({ name: 'NotFoundPage' });
    }

    next();
  } catch (error) {
    return next({ name: 'LoginPage' });
  }
};
