import { POSITION, useToast } from 'vue-toastification';
import type { ToastContent } from 'vue-toastification/dist/types/types';

export enum TOAST_VARIANT {
  info = 'info',
  error = 'error',
  success = 'success',
  warning = 'warning',
}

const TOAST_DURATION = 2000;

export const useToastNotify = () => {
  const toast = useToast();

  const openToast = (
    content: ToastContent,
    type = TOAST_VARIANT.success,
    position = POSITION.BOTTOM_CENTER,
    timeout = TOAST_DURATION,
  ) => {
    switch (type) {
      case TOAST_VARIANT[type]:
        toast[type](content, {
          position,
          timeout,
          toastClassName: `toast toast--${type}`,
        });
        break;

      default:
        toast[TOAST_VARIANT.info](content, {
          position,
          timeout,
          toastClassName: 'toast toast--info',
        });
        break;
    }
  };

  const clearAllToasts = () => {
    toast.clear();
  };

  return { openToast, clearAllToasts };
};
