import type {
  FILTER_TYPE,
  TValueFilter,
} from '@/modules/searchFilters/filters.types';
import type { TOptionComponent } from './tariffTable.types';
import type { TPersonalAreaData } from '@/modules/personalArea/fixations/types.ts';

export type TRow = {
  id: string;
  title: string;
  description: string;
  extraData?: string;
};
export type TList = Array<TRow>;

export interface IInputEvent extends InputEvent {
  target: HTMLInputElement;
}

export interface IDivEvent extends InputEvent {
  target: HTMLDivElement;
}

export type TValuesForm = Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  string | number | Array<string> | undefined | null | TValueFilter | any
>;

export enum ROUTER_LINK {
  MAIN_PAGE = '/',
  PEREUSTUPKI_PAGE = '/pereustupki',
  VTORICHNOE_GILIE_PAGE = '/vtorichnoe-gilie',
  IPOTEKA_PAGE = '/ipoteka',
  PRESENTATION_OBJECT_PAGE = '/presentation',
  PROJECT_PAGE = '/project',
  CLIENT_FIXATION_PAGE = '/client-fixation',
  FAVORITES_PAGE = '/favorites',
  CONPARISON_PAGE = '/favorites/comparison',
  RESERVATION_OBJECT_PAGE = '/reservation-object',
  LOGIN_PAGE = '/login',
  TARIFF_MAP_PAGE = '/tariff-map',
  PERSONAL_AREA = '/personal-area',
  PERSONAL_CLIENT = '/personal-client',
  NOT_FOUND_PAGE = '/not-found',
}

export enum DIRECTOR_MODE_TAB_ID {
  'My' = 'My',
  'All' = 'All',
}

export enum PERSONAL_AREA_LINK {
  PROFILE = 'profile',
  REALTORS = 'realtors',
  CLIENTS = 'clients',
  RESERVATIONS = 'reservations',
  DEALS = 'deals',
  FIXATIONS = 'fixations',
  DEVELOPERS = 'developers',
  SELECTIONS = 'selections',
}

export enum STATUS_CHIP {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  DANGER = 'danger',
  GHOST = 'ghost',
}

export type TImageGallery = {
  id: string;
  src: string;
  alt: string;
  title: string;
  description?: string;
};

export type TDocument = {
  id: string;
  corpus?: string;
  documents: string[];
  url: string;
};

export type TTab = {
  id: string;
  title: string;
};

export type TDiscount = {
  id: string;
  title: string;
  description: string;
  dates: string[];
};

/**
 * Характеристика (общий тип для всех характеристик в системе)
 */
export type TProperty = {
  /**
   * Идентификатор характеристики
   */
  id: string;
  /**
   * Название характеристики, например "Застройщик" или "Срок сдачи"
   */
  title: string;
  /**
   * Описание характеристики, например "PRINZIP Недвижимость" или "4 кв. 2025 года"
   */
  description: string;
  /**
   * Дополнительная информация для характеристики, например ссылка на сайт застройщика или "24 месяца до сдачи"
   */
  extraData?: string;
};

/**
 * DTO объекта в Попапе объекта
 */
export type TObjectDataModal = {
  objectData: {
    /**
     * Идентификатор объекта
     */
    id: number;
    /**
     * Название ЖК
     */
    housingComplex: string;
    /**
     * Идентификатор ЖК, нужен, чтобы перейти на страницу ЖК
     */
    housingComplexId: number;
    /**
     * Корпус
     */
    corpus: string;
    /**
     * Номер квартиры
     */
    number: string;
    /**
     * Тип квартиры
     */
    type: string;
    /**
     * Площадь
     */
    area: string;
    /**
     * Цена
     */
    price: number;
    /**
     * Комиссия
     */
    commission: number;
    /**
     * В подборке объект или нет
     */
    isLike: boolean;

    /**
     * Виды отображения объекта
     */
    objectViews: Array<{
      /**
       * id вида отображения
       */
      value: string;
      /**
       * Название вида отображения. Например "Планировка" , "С размерами" и т.д.
       */
      title: string;
      /**
       * Контент, которорый соответствует этому виду отображения. В данном случае это ссылка на картинку
       */
      content: string;
    }>;
    /**
     * Характеристики списком
     */
    properties: Array<TProperty>;
    roomsCount: string;
    rooms?: string;
  };

  dateUpdate: string;
  contacts: Array<{
    id: 'string';
    name: 'string';
    email: 'string';
    tel: 'string';
  }>;
  conditional: Array<{
    id: 'string';
    title: 'string';
    description: 'string';
    termType: 'string';
  }>;
};

/**
 * Контакт системы бронирования
 */
export type TContact = {
  id: string;
  name: string;
  tel: string;
  email: string;
};

/**
 * Условие бронирования/фиксации
 */
export type TConditionalReservation = {
  id: string;
  title: string;
  /**
   * В дизайне в описании Условий бронирования не просто сплошной текст, а верстка как в ворде,
   * возможно это будет заполняться кем-то и сбекенда должно приходить как часть HTML
   */
  description: string;
};

/**
 * Модель ответа на метод Попап объекта
 */
export type TModalObjectData = {
  /**
   * Данные об объекте
   */
  objectData: TObjectDataModal;
  /**
   * Контакты системы бронирования
   */
  contacts: Array<TContact>;
  /**
   * Условия брони и фиксации
   */
  conditions: Array<TConditionalReservation>;
  /**
   * Дата обновления данных
   */
  dateUpdate: string;
};

export type TFile = {
  id: string;
  name: string;
  size: number;
  extension?: string;
};

export type TUploadebleFile = TFile & {
  isAccepted?: boolean;
  rejectReason?: string;
  value?: File;
};

export enum FileUploadError {
  FILE_LIMIT_EXCEEDED = 'FILE_LIMIT_EXCEEDED',
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
  FILE_TYPE_NOT_EXIST = 'FILE_TYPE_NOT_EXIST',
}

export type TFileUploadError = {
  type: FileUploadError;
  message: string | undefined;
};

export enum FileSizeName {
  MB = 'mb',
  KB = 'kb',
}

export type TReservationObject = {
  id: number;
  developer: string;
  developerId: number;
  housingComplex: string;
  address?: string;
  seller?: string;
  number?: string;
  frame?: string;
  finishing?: string;
  area?: number;
  kitchenArea?: number;
  floor: number;
  decoration: string;
  price: number;
  pricePerMeter: number;
  commission?: number;
  imageUrl?: string;
  bookingTerm: string;
};

export type TRealtorClient = {
  id: number;
  clientFixingId: number;
  name: string;
  tel: string;
  email?: string;
  lastName: string;
  surName?: string;
  passport: string;
  realtorId: number;
};

export type TFieldType = {
  fieldType: string;
};

export type TReservationObjectForm = {
  name: string | null;
  lastName: string | null;
  surName: string | null;
  email: string | null;
  tel: string | null;
  passport: string | null;
  payments: Array<string> | null;
  comment: string | null;
  files: Array<string | File> | null;
};

export type TPerson = {
  fullName: string;
  position: string;
  tel: string;
  email: string;
  photo: string;
};

export type TAgency = {
  name: string;
  description: string;
  address: string;
  tel: string;
  email: string;
  logo: string;
  legalAddress: string;
  inn: string;
  kpp: string;
  ogrn: string;
  ogrnDate: string;
};

export type TAddNewRealtorForm = {
  name: string | null;
  lastName: string | null;
  surName: string | null;
  email: string | null;
  tel: string | null;
};

export const COMMISSION_TITLE = 'Commission';

export type TFilterValuesRequest = {
  filterId: string;
  value: string | Array<string>;
};

export type TPaginationRequest = {
  count: number;
  startIndex: number;
};

export type TSortFormRequest = {
  columnId: string;
  order: string;
};

export type TRequestListTablesProfile = {
  searchString?: string;
  filtersValues: Array<TFilterValuesRequest>;
  pagination: TPaginationRequest;
  sortForm?: TSortFormRequest;
  agencyHeadQueryType?: string;
};

export enum AGENCY_HEAD_PROFILE_TAB_ID {
  'MyClients' = '1',
  'All' = '2',
}

export type TResponseValuesFilterData = {
  value: string;
  title: string;
};

export type TResponseFilterData = {
  id: string;
  searchPlaceholder: string;
  type: string;
  values: Array<TResponseValuesFilterData>;
};

export type TTableHeader = {
  id: string;
  name: string;
  isSorted?: boolean;
  filter?: TResponseFilterData;
};

export type TResponseColumnData = {
  isSorted: boolean;
  filter: TResponseFilterData;
  id: string;
  name: string;
};

export enum AGENCY_HEAD_QUERY_TYPE {
  My = 'My', // Только мои записи
  Staff = 'Staff', // Записи сотрудников
  All = 'All', // Все записи
}

export enum OBJECT_TYPE {
  Apartment = 'Apartment',
  Flat = 'Flat',
  Parking = 'Parking',
  Pantry = 'Pantry',
  ComObject = 'ComObject',
}

export type TReasonsResponse = {
  value: string;
  title: string;
  hint: string;
};

export type TRequestChangesData = {
  id: number | string;
  create: string;
  comment: string;
  adminComment: string;
  change: string;
};

export type TRequestChangesTableData = {
  changes: Array<TRequestChangesData>;
  columns: Array<TResponseColumnData>;
};

export type TRequestDataTableChanges = {
  id: number | null;
  sortForm?: TSortFormRequest;
};

export enum OBJECT_PLACEHOLDER_IMAGE_TYPE {
  Plan = 'Plan',
  Project = 'Project',
  Object = 'Object',
}

export enum CONDITIONS_ARMOR_AND_FIXATION {
  Fixing = 'Fixing',
  Payment = 'Payment',
}
