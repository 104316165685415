import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type {
  TProfileRealtor,
  TProfileRealtorData,
} from '@/modules/personalArea/profile/types.ts';
import { postUpdateWorkWithClient } from '@/modules/personalArea/profile/service/profile-page.transport.ts';

export enum USER_ROLE {
  ADMIN = 'Admin',
  SELF_EMPLOYED = 'SelfEmployed',
  AGENCY_HEAD = 'AgencyHead',
  AGENCY_STAFF = 'AgencyStaff',
}

const ADMINISTRATION_ROLES = [USER_ROLE.ADMIN, USER_ROLE.AGENCY_HEAD];

export enum DATA_ACCESS_RESTRICTION_FLAG {
  /** Управляющие */
  ADMINISTRATION = 'administration',
}

export const useProfileService = defineStore('profileService', () => {
  const profile = ref<TProfileRealtor>();
  const userRole = computed<USER_ROLE>(() => profile.value?.role as USER_ROLE);
  const isWorkWithClient = computed(
    () => profile.value?.workWithClient ?? false,
  );

  const setProfile = (userProfile: TProfileRealtor) => {
    profile.value = userProfile;
  };

  const setWorkWithClient = (workWithClient: boolean) => {
    if (!profile.value) return;

    profile.value.workWithClient = workWithClient;
  };

  const initProfileService = (userData: TProfileRealtor) => {
    setProfile(userData);
  };

  const changeWorkWithClient = async (isActive: boolean) => {
    setWorkWithClient(isActive);
    await postUpdateWorkWithClient(isWorkWithClient.value);
  };

  const checkHasAdministrationAccess = (profile: TProfileRealtor) =>
    ADMINISTRATION_ROLES.includes(profile.role as USER_ROLE);

  const RESTRICTION_VALIDATOR = {
    [DATA_ACCESS_RESTRICTION_FLAG.ADMINISTRATION]: checkHasAdministrationAccess,
  };

  const checkProfileAccessToData = (
    restrictions: Array<DATA_ACCESS_RESTRICTION_FLAG>,
    profile: TProfileRealtor,
  ) => {
    const access = restrictions.map(
      (restriction) => RESTRICTION_VALIDATOR[restriction]?.(profile) ?? true,
    );

    if (access.every((access) => !!access)) {
      return true;
    }

    return false;
  };

  return {
    profile,
    userRole,
    initProfileService,
    setWorkWithClient,
    changeWorkWithClient,
    isWorkWithClient,
    checkProfileAccessToData,
  };
});
