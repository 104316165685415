import { createVfm } from 'vue-final-modal';
import '@/styles/_global.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';

import { IconHeart, IconUsers } from '@/assets/icons/index.ts';
import App from './App.vue';
import router from '@/router/index.ts';
import { toastOptions } from './shared/Toast.ts';

const app = createApp(App);
const vfm = createVfm();

app.component('IconHeart', IconHeart);
app.component('IconUsers', IconUsers);

app.use(createPinia());
app.use(router);
app.use(vfm);

app.use(Toast, toastOptions);

app.mount('#app');
