import type { PluginOptions } from 'vue-toastification';
import IconWhiteClose from '@/assets/svg/IconWhiteClose.svg?component';
import '@/styles/toast.scss';

/**
 * Это настройки для плагина Toast, никак в компонентах не используется!!
 * Дока бибилиотеки - https://github.com/Maronato/vue-toastification?tab=readme-ov-file
 */
export const toastOptions: PluginOptions = {
  icon: false,
  closeButton: IconWhiteClose,
  toastClassName: 'toast',
  closeButtonClassName: 'toast__close-button',
  transition: 'Vue-Toastification__fade',
};
