<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.5456 5.48541C18.6031 4.53243 17.3552 4.01157 16.0235 4.01157C14.6917 4.01157 13.44 4.53629 12.4975 5.48927L12.0052 5.98698L11.5053 5.48155C10.5628 4.52857 9.30734 4 7.97557 4C6.64761 4 5.39597 4.52472 4.45724 5.47384C3.51469 6.42681 2.99572 7.69231 2.99953 9.03882C2.99953 10.3853 3.52232 11.647 4.46487 12.6L11.6313 19.8457C11.7305 19.946 11.864 20 11.9938 20C12.1235 20 12.2571 19.9498 12.3563 19.8495L19.538 12.6154C20.4805 11.6624 20.9995 10.3969 20.9995 9.0504C21.0033 7.70388 20.4882 6.43839 19.5456 5.48541Z"
      stroke="#29277D"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>
