import { createRouter, createWebHistory } from 'vue-router';
import { ROUTER_LINK } from '../shared/types.ts';
import { tryToEnterPrivateRoute } from './guards.ts';
import { ROUTES } from './routes.ts';

const router = createRouter({
  history: createWebHistory(),
  routes: ROUTES,
});

export const PUBLIC_ROUTES: Array<string> = [
  ROUTER_LINK.LOGIN_PAGE,
  ROUTER_LINK.NOT_FOUND_PAGE,
  ROUTER_LINK.PERSONAL_CLIENT,
  ROUTER_LINK.PRESENTATION_OBJECT_PAGE,
];

router.beforeEach(async (to, from, next) => {
  if (!PUBLIC_ROUTES.includes(to.path)) {
    await tryToEnterPrivateRoute(to, from, next);
  } else {
    next();
  }
});

export default router;
