import { httpClient } from '@/core/services/http-client.service.ts';
import type {
  TConfirmEmailRequestData,
  TEmailForm,
  TLoginData,
  TRegisterData,
  TResetPasswordData,
} from '../types.ts';

export const loginFetch = async (data: TLoginData): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/login', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const logoutFetch = async (): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/logout');

    return response;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordFetch = async (data: TEmailForm): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/forgotpassword', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const resetPasswordFetch = async (
  data: TResetPasswordData,
): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/resetpassword', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const registerAccountFetch = async (
  data: TRegisterData,
): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/registerprofile', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const checkEmail = async (userName: string): Promise<boolean> => {
  try {
    return (
      await httpClient.get<boolean>(`/auth/checkemail?username=${userName}`)
    ).data;
  } catch (error) {
    throw error;
  }
};

export const sendConfirmEmail = async (data: TEmailForm): Promise<boolean> => {
  try {
    return (
      await httpClient.get<boolean>(
        `/auth/requestemailconfirm?email=${data.email}`,
      )
    ).data;
  } catch (error) {
    throw error;
  }
};

export const confirmEmailFetch = async (
  data: TConfirmEmailRequestData,
): Promise<any> => {
  try {
    const response = await httpClient.post('/auth/confirm', data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const checkAuthFetch = async (): Promise<boolean> => {
  try {
    return (await httpClient.get<boolean>(`/auth/checkauth`)).data;
  } catch (error) {
    throw error;
  }
};
